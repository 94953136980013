import React from 'react'
import FinancialForm from './FinancialForm'

export default function Main() {
    return (
        <div class="m-5 border-5">

            <h3>Información no contractual</h3>
            <h5>Este es un mero cálculo financiero. No le estamos ofreciendo un crédito, solo facilitándole los cálculos.</h5>
            <FinancialForm />
        </div>
    )
}

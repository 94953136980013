import React, { useState } from 'react';
import './FinancialForm.css';
import moment from 'moment';

export default function FinancialForm() {
  const [montoCredito, setMontoCredito] = useState('');
  const [tasa, setTasa] = useState('');
  const [plazo, setPlazo] = useState(36);
  const [vencimientos, setVencimientos] = useState('mensuales');
  const [diferirVencimiento, setDiferirVencimiento] = useState(false);
  const [fechaLiquidacion, setFechaLiquidacion] = useState('');
  const [fechaPrimerVencimiento, setFechaPrimerVencimiento] = useState('');
  const [resultado, setResultado] = useState('');
  const [cuotas, setCuotas] = useState([]);

  const handleMontoCreditoChange = (e) => {
    const { value } = e.target;
    setMontoCredito(value.replace(/[^0-9]/g, ''));
  };

  const handleTasaChange = (e) => {
    const { value } = e.target;
    setTasa(value.replace(/[^0-9.]/g, ''));
  };

  const handlePlazoChange = (e) => {
    setPlazo(e.target.value);
  };

  const handleVencimientosChange = (e) => {
    const { value } = e.target;
    setVencimientos(value);
    if (value !== 'semestrales') {
      setDiferirVencimiento(false);
    }
  };

  const handleDiferirVencimientoChange = (e) => {
    setDiferirVencimiento(e.target.checked);
  };

  const formatDate = (date) => {
    const formattedDate = moment(date, ['YYYY-MM-DD']).format('DD/MM/YY');
    return formattedDate !== 'Invalid date' ? formattedDate : '';
  };

  const handleFechaLiquidacionChange = (e) => {
    setFechaLiquidacion(e.target.value);
  };

  const handleFechaPrimerVencimientoChange = (e) => {
    setFechaPrimerVencimiento(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Realizar cálculos con los datos ingresados
    const cantidadDeCuotas =
      vencimientos === 'mensuales'
        ? plazo / 30
        : vencimientos === 'trimestrales'
        ? plazo / 91
        : plazo / 182;

    const plazoDelPeriodo =
      vencimientos === 'mensuales'
        ? 30
        : vencimientos === 'trimestrales'
        ? 91
        : 182;

    const capitalPrimeraCuota =
      ((tasa / 365) * plazoDelPeriodo) /
        (1 - Math.pow(tasa / 365 * plazoDelPeriodo, -cantidadDeCuotas)) *
        montoCredito -
      montoCredito * (tasa / 365) * plazoDelPeriodo;

    // Generar las cuotas
    const newCuotas = [];
    for (let i = 1; i <= cantidadDeCuotas; i++) {
      const cuota = {
        numero: i,
        capital: i === 1 ? capitalPrimeraCuota : 'pendiente',
        interes: 'pendiente',
        montoTotal: 'pendiente'
      };
      newCuotas.push(cuota);
    }

    setCuotas(newCuotas);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <form className="m-2 p-4 form-control" onSubmit={handleSubmit}>
            <fieldset className="row mb-3">
              <div className="col-md-3">
                <label htmlFor="montoCredito" className="form-label">
                  Monto del crédito
                </label>
                <input
                  type="text"
                  pattern="^[0-9]{1,10}$"
                  className="form-control"
                  id="montoCredito"
                  placeholder="Valor numérico"
                  value={montoCredito}
                  onChange={handleMontoCreditoChange}
                  title="Ingrese un valor numérico de hasta 10 dígitos"
                  required
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="tasa" className="form-label">
                  Ingrese la Tasa
                </label>
                <input
                  type="text"
                  pattern="^\d{1,4}(\.\d{1,2})?$"
                  className="form-control"
                  id="tasa"
                  placeholder="TNA"
                  value={tasa}
                  onChange={handleTasaChange}
                  title="Ingrese un valor numérico del 0 al 1000 con hasta 2 decimales"
                  required
                />
              </div>
            </fieldset>
            <div className="mb-3">
              <label htmlFor="customRange2" className="form-label">
                Plazo en cantidad de meses: {plazo} meses (elige el plazo ideal usando la barra)
              </label>
              <input
                type="range"
                className="form-range col-sm-9"
                min="0"
                max="60"
                id="customRange2"
                value={plazo}
                onChange={handlePlazoChange}
              />
            </div>

            <fieldset className="row mb-3">
              <legend className="col-form-label col-sm-2 pt-0">Vencimientos</legend>
              <div className="col-sm-9">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios1"
                    value="mensuales"
                    checked={vencimientos === 'mensuales'}
                    onChange={handleVencimientosChange}
                  />
                  <label className="form-check-label" htmlFor="gridRadios1">
                    Mensuales
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios2"
                    value="trimestrales"
                    checked={vencimientos === 'trimestrales'}
                    onChange={handleVencimientosChange}
                  />
                  <label className="form-check-label" htmlFor="gridRadios2">
                    Trimestrales
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gridRadios"
                    id="gridRadios3"
                    value="semestrales"
                    checked={vencimientos === 'semestrales'}
                    onChange={handleVencimientosChange}
                  />
                  <label className="form-check-label" htmlFor="gridRadios3">
                    Semestrales
                  </label>
                </div>
                <div className="form-check form-switch form-check-inline form-check-reverse">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckReverse"
                    disabled={vencimientos !== 'semestrales'}
                    checked={diferirVencimiento}
                    onChange={handleDiferirVencimientoChange}
                  />
                  <label className="form-check-label" htmlFor="flexSwitchCheckReverse">
                    ¿Diferir primer vencimiento a un año?
                  </label>
                </div>
              </div>
            </fieldset>

            <fieldset className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="fechaLiquidacion" className="form-label">
                  Fecha de Liquidación
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="fechaLiquidacion"
                  placeholder="DD/MM/AAAA"
                  value={fechaLiquidacion}
                  onChange={handleFechaLiquidacionChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="fechaPrimerVencimiento" className="form-label">
                  Fecha de Primer Vencimiento
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="fechaPrimerVencimiento"
                  placeholder="DD/MM/AAAA"
                  value={fechaPrimerVencimiento}
                  onChange={handleFechaPrimerVencimientoChange}
                  required
                />
              </div>
            </fieldset>
            <div className="d-grid gap-2">
              <button type="submit" className="btn btn-primary">
                Calcular
              </button>
            </div>
            {cuotas.length > 0 && (
              <div className="mt-4">
                <h5>Resultado:</h5>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Cuota</th>
                      <th scope="col">Capital</th>
                      <th scope="col">Interés</th>
                      <th scope="col">Monto Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cuotas.map((cuota, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{cuota.capital}</td>
                        <td>{cuota.interes}</td>
                        <td>{cuota.montoTotal}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}




// ==== FIN DE LA VERSIÓN =======

// import React, { useState } from 'react';
// import './FinancialForm.css';
// import moment from 'moment';

// export default function FinancialForm() {
//     const [montoCredito, setMontoCredito] = useState('');
//     const [tasa, setTasa] = useState('');
//     const [plazo, setPlazo] = useState(36);
//     const [vencimientos, setVencimientos] = useState('mensuales');
//     const [diferirVencimiento, setDiferirVencimiento] = useState(false);
//     const [fechaLiquidacion, setFechaLiquidacion] = useState('');
//     const [fechaPrimerVencimiento, setFechaPrimerVencimiento] = useState('');
//     const [resultado, setResultado] = useState('');
//     const [cuotas, setCuotas] = useState([]);

//     const handleMontoCreditoChange = (e) => {
//         const { value } = e.target;
//         setMontoCredito(value.replace(/[^0-9]/g, ''));
//     };

//     const handleTasaChange = (e) => {
//         const { value } = e.target;
//         setTasa(value.replace(/[^0-9.]/g, ''));
//     };

//     const handlePlazoChange = (e) => {
//         setPlazo(e.target.value);
//     };

//     const handleVencimientosChange = (e) => {
//         const { value } = e.target;
//         setVencimientos(value);
//         if (value !== 'semestrales') {
//             setDiferirVencimiento(false);
//         }
//     };

//     const handleDiferirVencimientoChange = (e) => {
//         setDiferirVencimiento(e.target.checked);
//     };

//     const formatDate = (date) => {
//         const formattedDate = moment(date, ['YYYY-MM-DD']).format('DD/MM/YY');
//         return formattedDate !== 'Invalid date' ? formattedDate : '';
//     };

//     const handleFechaLiquidacionChange = (e) => {
//         setFechaLiquidacion(e.target.value);
//     };

//     const handleFechaPrimerVencimientoChange = (e) => {
//         setFechaPrimerVencimiento(e.target.value);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         // Realizar cálculos con los datos ingresados
//         const cantidadDeCuotas =
//             vencimientos === 'mensuales'
//                 ? plazo / 30
//                 : vencimientos === 'trimestrales'
//                     ? plazo / 91
//                     : plazo / 182;

//         const plazoDelPeriodo =
//             vencimientos === 'mensuales'
//                 ? 30
//                 : vencimientos === 'trimestrales'
//                     ? 91
//                     : 182;

//         const capitalPrimeraCuota =
//             ((tasa / 365) * plazoDelPeriodo) /
//             (1 - Math.pow(1 + tasa / 365 * plazoDelPeriodo, -cantidadDeCuotas)) *
//             montoCredito -
//             montoCredito * (tasa / 365 * plazoDelPeriodo);

//         const nuevaCuotas = [
//             {
//                 capital: capitalPrimeraCuota.toFixed(2),
//                 interes: 'Pendiente',
//                 montoTotal: 'Pendiente',
//             },
//         ];

//         for (let i = 1; i < cantidadDeCuotas; i++) {
//             nuevaCuotas.push({
//                 capital: 'Pendiente',
//                 interes: 'Pendiente',
//                 montoTotal: 'Pendiente',
//             });
//         }

//         // Guardar el resultado y las cuotas en el estado
//         setResultado('Aquí va el resultado de los cálculos');
//         setCuotas(nuevaCuotas);
//     };

//     const renderCuotas = () => {
//         return cuotas.map((cuota, index) => (
//             <tr key={index}>
//                 <td>{index + 1}</td>
//                 <td>{cuota.capital}</td>
//                 <td>{cuota.interes}</td>
//                 <td>{cuota.montoTotal}</td>
//             </tr>
//         ));
//     };

//     return (
//         <div className="container">
//             <div className="row">
//                 <div className="col-lg-6">
//                     <form className="m-2 p-4 form-control" onSubmit={handleSubmit}>
//                         <div className="form-group">
//                             <label htmlFor="montoCredito">Monto del crédito:</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 id="montoCredito"
//                                 value={montoCredito}
//                                 onChange={handleMontoCreditoChange}
//                                 placeholder="Ingrese el monto del crédito"
//                             />
//                         </div>

//                         <div className="form-group">
//                             <label htmlFor="tasa">Tasa de interés:</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 id="tasa"
//                                 value={tasa}
//                                 onChange={handleTasaChange}
//                                 placeholder="Ingrese la tasa de interés"
//                             />
//                         </div>

//                         <div className="form-group">
//                             <label htmlFor="plazo">Plazo (días):</label>
//                             <input
//                                 type="number"
//                                 className="form-control"
//                                 id="plazo"
//                                 value={plazo}
//                                 onChange={handlePlazoChange}
//                                 placeholder="Ingrese el plazo en días"
//                             />
//                         </div>

//                         <div className="form-group">
//                             <label htmlFor="vencimientos">Vencimientos:</label>
//                             <select
//                                 className="form-control"
//                                 id="vencimientos"
//                                 value={vencimientos}
//                                 onChange={handleVencimientosChange}
//                             >
//                                 <option value="mensuales">Mensuales</option>
//                                 <option value="trimestrales">Trimestrales</option>
//                                 <option value="semestrales">Semestrales</option>
//                             </select>
//                         </div>

//                         {vencimientos === 'semestrales' && (
//                             <div className="form-check">
//                                 <input
//                                     className="form-check-input"
//                                     type="checkbox"
//                                     id="diferirVencimiento"
//                                     checked={diferirVencimiento}
//                                     onChange={handleDiferirVencimientoChange}
//                                 />
//                                 <label
//                                     className="form-check-label"
//                                     htmlFor="diferirVencimiento"
//                                 >
//                                     Diferir vencimiento
//                                 </label>
//                             </div>
//                         )}

//                         {diferirVencimiento && (
//                             <div className="form-group">
//                                 <label htmlFor="fechaLiquidacion">Fecha de liquidación:</label>
//                                 <input
//                                     type="date"
//                                     className="form-control"
//                                     id="fechaLiquidacion"
//                                     value={fechaLiquidacion}
//                                     onChange={handleFechaLiquidacionChange}
//                                 />
//                             </div>
//                         )}

//                         {diferirVencimiento && (
//                             <div className="form-group">
//                                 <label htmlFor="fechaPrimerVencimiento">
//                                     Fecha primer vencimiento:
//                                 </label>
//                                 <input
//                                     type="date"
//                                     className="form-control"
//                                     id="fechaPrimerVencimiento"
//                                     value={fechaPrimerVencimiento}
//                                     onChange={handleFechaPrimerVencimientoChange}
//                                 />
//                             </div>
//                         )}

//                         <button type="submit" className="btn btn-primary">
//                             Calcular
//                         </button>
//                     </form>
//                 </div>
//                 <div className="col-lg-6">
//                     {resultado && (
//                         <div className="m-2 p-4 form-control">
//                             <h4>Resultado:</h4>
//                             <p>{resultado}</p>
//                         </div>
//                     )}
//                     {cuotas.length > 0 && (
//                         <div className="m-2 p-4 form-control">
//                             <h4>Cuotas:</h4>
//                             <table className="table">
//                                 <thead>
//                                     <tr>
//                                         <th>Número</th>
//                                         <th>Capital</th>
//                                         <th>Interés</th>
//                                         <th>Monto Total</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>{renderCuotas()}</tbody>
//                             </table>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }




//  FIN VERSIÓN======================================



// import React, { useState } from 'react';
// import './FinancialForm.css';
// import moment from 'moment';

// export default function FinancialForm() {
//     const [montoCredito, setMontoCredito] = useState('');
//     const [tasa, setTasa] = useState('');
//     const [plazo, setPlazo] = useState(36);
//     const [vencimientos, setVencimientos] = useState('mensuales');
//     const [diferirVencimiento, setDiferirVencimiento] = useState(false);
//     const [fechaLiquidacion, setFechaLiquidacion] = useState('');
//     const [fechaPrimerVencimiento, setFechaPrimerVencimiento] = useState('');
//     const [resultado, setResultado] = useState('');

//     const handleMontoCreditoChange = (e) => {
//         const { value } = e.target;
//         setMontoCredito(value.replace(/[^0-9]/g, ''));
//     };

//     const handleTasaChange = (e) => {
//         const { value } = e.target;
//         setTasa(value.replace(/[^0-9.]/g, ''));
//     };

//     const handlePlazoChange = (e) => {
//         setPlazo(e.target.value);
//     };

//     const handleVencimientosChange = (e) => {
//         const { value } = e.target;
//         setVencimientos(value);
//         if (value !== 'semestrales') {
//             setDiferirVencimiento(false);
//         }
//     };

//     const handleDiferirVencimientoChange = (e) => {
//         setDiferirVencimiento(e.target.checked);
//     };

//     const formatDate = (date) => {
//         const formattedDate = moment(date, ['YYYY-MM-DD']).format('DD/MM/YY');
//         return formattedDate !== 'Invalid date' ? formattedDate : '';
//     };

//     const handleFechaLiquidacionChange = (e) => {
//         setFechaLiquidacion(e.target.value);
//     };

//     const handleFechaPrimerVencimientoChange = (e) => {
//         setFechaPrimerVencimiento(e.target.value);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Realizar cálculos con los datos ingresados
//         // ...
//         // Guardar el resultado en el estado
//         setResultado('Aquí va el resultado de los cálculos');
//     };

//     return (
//         <div className="container">
//             <div className="row">
//                 <div className="col-lg-6">
//                     <form className="m-2 p-4 form-control" onSubmit={handleSubmit}>
//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="montoCredito" className="form-label">
//                                     Monto del crédito
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^[0-9]{1,10}$"
//                                     className="form-control"
//                                     id="montoCredito"
//                                     placeholder="Valor numérico"
//                                     value={montoCredito}
//                                     onChange={handleMontoCreditoChange}
//                                     title="Ingrese un valor numérico de hasta 10 dígitos"
//                                     required
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="tasa" className="form-label">
//                                     Ingrese la Tasa
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^\d{1,4}(\.\d{1,2})?$"
//                                     className="form-control"
//                                     id="tasa"
//                                     placeholder="TNA"
//                                     value={tasa}
//                                     onChange={handleTasaChange}
//                                     title="Ingrese un valor numérico del 0 al 1000 con hasta 2 decimales"
//                                     required
//                                 />
//                             </div>
//                         </fieldset>
//                         <div className="mb-3">
//                             <label htmlFor="customRange2" className="form-label">
//                                 Plazo en cantidad de meses: {plazo} meses (elige el plazo ideal usando la barra)
//                             </label>
//                             <input
//                                 type="range"
//                                 className="form-range col-sm-9"
//                                 min="0"
//                                 max="60"
//                                 id="customRange2"
//                                 value={plazo}
//                                 onChange={handlePlazoChange}
//                             />
//                         </div>

//                         <fieldset className="row mb-3">
//                             <legend className="col-form-label col-sm-2 pt-0">Vencimientos</legend>
//                             <div className="col-sm-9">
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios1"
//                                         value="mensuales"
//                                         checked={vencimientos === 'mensuales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios1">
//                                         Mensuales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios2"
//                                         value="trimestrales"
//                                         checked={vencimientos === 'trimestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios2">
//                                         Trimestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios3"
//                                         value="semestrales"
//                                         checked={vencimientos === 'semestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios3">
//                                         Semestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-switch form-check-inline form-check-reverse">
//                                     <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         id="flexSwitchCheckReverse"
//                                         disabled={vencimientos !== 'semestrales'}
//                                         checked={diferirVencimiento}
//                                         onChange={handleDiferirVencimientoChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="flexSwitchCheckReverse">
//                                         ¿Diferir primer vencimiento a un año?
//                                     </label>
//                                 </div>
//                             </div>
//                         </fieldset>

//                         <fieldset className="row mb-3">
//                             <div className="col-md-6">
//                                 <label htmlFor="fechaLiquidacion" className="form-label">
//                                     Fecha de Liquidación
//                                 </label>
//                                 <input
//                                     type="date"
//                                     className="form-control"
//                                     id="fechaLiquidacion"
//                                     placeholder="DD/MM/AAAA"
//                                     value={fechaLiquidacion}
//                                     onChange={handleFechaLiquidacionChange}
//                                     required
//                                 />
//                             </div>
//                             <div className="col-md-6">
//                                 <label htmlFor="fechaPrimerVencimiento" className="form-label">
//                                     Fecha de Primer Vencimiento
//                                 </label>
//                                 <input
//                                     type="date"
//                                     className="form-control"
//                                     id="fechaPrimerVencimiento"
//                                     placeholder="DD/MM/AAAA"
//                                     value={fechaPrimerVencimiento}
//                                     onChange={handleFechaPrimerVencimientoChange}
//                                     required
//                                 />
//                             </div>
//                         </fieldset>
//                         <div className="d-grid gap-2">
//                             <button type="submit" className="btn btn-primary">
//                                 Calcular
//                             </button>
//                         </div>
//                         {resultado && (
//                             <div className="mt-4">
//                                 <h5>Resultado:</h5>
//                                 <p>{resultado}</p>
//                             </div>
//                         )}
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// }






// FIN VERSIÓN ======================================











// import React, { useState } from 'react';
// import './FinancialForm.css';

// export default function FinancialForm() {
//     const [montoCredito, setMontoCredito] = useState('');
//     const [tasa, setTasa] = useState('');
//     const [plazo, setPlazo] = useState(36);
//     const [vencimientos, setVencimientos] = useState('mensuales');
//     const [diferirVencimiento, setDiferirVencimiento] = useState(false);
//     const [fechaLiquidacion, setFechaLiquidacion] = useState('');
//     const [fechaPrimerVencimiento, setFechaPrimerVencimiento] = useState('');
//     const [resultado, setResultado] = useState('');

//     const handleMontoCreditoChange = (e) => {
//         const { value } = e.target;
//         setMontoCredito(value.replace(/[^0-9]/g, ''));
//     };

//     const handleTasaChange = (e) => {
//         const { value } = e.target;
//         setTasa(value.replace(/[^0-9.]/g, ''));
//     };

//     const handlePlazoChange = (e) => {
//         setPlazo(e.target.value);
//     };

//     const handleVencimientosChange = (e) => {
//         const { value } = e.target;
//         setVencimientos(value);
//         if (value !== 'semestrales') {
//             setDiferirVencimiento(false);
//         }
//     };

//     const handleDiferirVencimientoChange = (e) => {
//         setDiferirVencimiento(e.target.checked);
//     };

//     const handleFechaLiquidacionChange = (e) => {
//         const { value } = e.target;
//         const formattedDate = formatDate(value);
//         setFechaLiquidacion(formattedDate);
//     };

//     const handleFechaPrimerVencimientoChange = (e) => {
//         const { value } = e.target;
//         const formattedDate = formatDate(value);
//         setFechaPrimerVencimiento(formattedDate);
//     };

//     const formatDate = (dateString) => {
//         // Validar si el formato ingresado es ddmmaa o ddmmaaaa
//         if (dateString.length === 6 && /^\d+$/.test(dateString)) {
//             const day = dateString.substr(0, 2);
//             const month = dateString.substr(2, 2);
//             const year = dateString.substr(4);
//             return `${day}/${month}/${year}`;
//         }

//         // Validar si el formato ingresado es dd/mm/aa o dd/mm/aaaa
//         if (dateString.length === 8 && /^\d+$/.test(dateString)) {
//             const day = dateString.substr(0, 2);
//             const month = dateString.substr(2, 2);
//             const year = dateString.substr(4);
//             return `${day}/${month}/${year}`;
//         }

//         return dateString;
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Realizar cálculos con los datos ingresados
//         // ...
//         // Guardar el resultado en el estado
//         setResultado('Aquí va el resultado de los cálculos');
//     };

//     return (
//         <div className="container">
//             <div className="row">
//                 <div className="col-lg-6">
//                     <form className="m-2 p-4 form-control" onSubmit={handleSubmit}>
//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="montoCredito" className="form-label">
//                                     Monto del crédito
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^[0-9]{1,10}$"
//                                     className="form-control"
//                                     id="montoCredito"
//                                     placeholder="Valor numérico"
//                                     value={montoCredito}
//                                     onChange={handleMontoCreditoChange}
//                                     title="Ingrese un valor numérico de hasta 10 dígitos"
//                                     required
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="tasa" className="form-label">
//                                     Ingrese la Tasa
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^\d{1,4}(\.\d{1,2})?$"
//                                     className="form-control"
//                                     id="tasa"
//                                     placeholder="TNA"
//                                     value={tasa}
//                                     onChange={handleTasaChange}
//                                     title="Ingrese un valor numérico del 0 al 1000 con hasta 2 decimales"
//                                     required
//                                 />
//                             </div>
//                         </fieldset>
//                         <div className="mb-3">
//                             <label htmlFor="customRange2" className="form-label">
//                                 Plazo en cantidad de meses: {plazo} meses (elige el plazo ideal usando la barra)
//                             </label>
//                             <input
//                                 type="range"
//                                 className="form-range col-sm-9"
//                                 min="0"
//                                 max="60"
//                                 id="customRange2"
//                                 value={plazo}
//                                 onChange={handlePlazoChange}
//                             />
//                         </div>

//                         <fieldset className="row mb-3">
//                             <legend className="col-form-label col-sm-2 pt-0">Vencimientos</legend>
//                             <div className="col-sm-9">
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios1"
//                                         value="mensuales"
//                                         checked={vencimientos === 'mensuales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios1">
//                                         Mensuales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios2"
//                                         value="trimestrales"
//                                         checked={vencimientos === 'trimestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios2">
//                                         Trimestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios3"
//                                         value="semestrales"
//                                         checked={vencimientos === 'semestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios3">
//                                         Semestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-switch form-check-inline form-check-reverse">
//                                     <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         id="flexSwitchCheckReverse"
//                                         disabled={vencimientos !== 'semestrales'}
//                                         checked={diferirVencimiento}
//                                         onChange={handleDiferirVencimientoChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="flexSwitchCheckReverse">
//                                         ¿Diferir primer vencimiento a un año?
//                                     </label>
//                                 </div>
//                             </div>
//                         </fieldset>

//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaLiquidacion" className="form-label">
//                                     Fecha de liquidación
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^(0[1-9]|1[0-2]|[1-9])(0[1-9]|1\d|2[0-8]|[1-9])(\d{2}|\d{4})$"
//                                     className="form-control"
//                                     id="fechaLiquidacion"
//                                     placeholder="Opcional"
//                                     value={fechaLiquidacion}
//                                     onChange={handleFechaLiquidacionChange}
//                                     title="Ingrese una fecha válida en formato dd/mm/aa o dd/mm/aaaa"
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaPrimerVencimiento" className="form-label">
//                                     Fecha primer vencimiento
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^(0[1-9]|1[0-2]|[1-9])(0[1-9]|1\d|2[0-8]|[1-9])(\d{2}|\d{4})$"
//                                     className="form-control"
//                                     id="fechaPrimerVencimiento"
//                                     placeholder="Opcional"
//                                     value={fechaPrimerVencimiento}
//                                     onChange={handleFechaPrimerVencimientoChange}
//                                     title="Ingrese una fecha válida en formato dd/mm/aa o dd/mm/aaaa"
//                                 />
//                             </div>
//                         </fieldset>

//                         <div className="col-12">
//                             <button type="submit" className="btn btn-primary">
//                                 Calcular
//                             </button>
//                         </div>
//                     </form>
//                 </div>

//                 <div className="col-lg-6">
//                     <div className="m-2 p-4 custom-box1">
//                         <h5>Desarrollo del crédito:</h5>
//                         {resultado ? <div>{resultado}</div> : <div>Nada que Mostrar todavía</div>}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }






// import React, { useState } from 'react';
// import './FinancialForm.css';

// export default function FinancialForm() {
//     const [montoCredito, setMontoCredito] = useState('');
//     const [tasa, setTasa] = useState('');
//     const [plazo, setPlazo] = useState(36);
//     const [vencimientos, setVencimientos] = useState('mensuales');
//     const [diferirVencimiento, setDiferirVencimiento] = useState(false);
//     const [fechaLiquidacion, setFechaLiquidacion] = useState('');
//     const [fechaPrimerVencimiento, setFechaPrimerVencimiento] = useState('');
//     const [resultado, setResultado] = useState('');

//     const handleMontoCreditoChange = (e) => {
//         const { value } = e.target;
//         setMontoCredito(value.replace(/[^0-9]/g, ''));
//     };

//     const handleTasaChange = (e) => {
//         const { value } = e.target;
//         setTasa(value.replace(/[^0-9.]/g, ''));
//     };

//     const handlePlazoChange = (e) => {
//         setPlazo(e.target.value);
//     };

//     const handleVencimientosChange = (e) => {
//         const { value } = e.target;
//         setVencimientos(value);
//         if (value !== 'semestrales') {
//             setDiferirVencimiento(false);
//         }
//     };

//     const handleDiferirVencimientoChange = (e) => {
//         setDiferirVencimiento(e.target.checked);
//     };

//     const handleFechaLiquidacionChange = (e) => {
//         const { value } = e.target;
//         setFechaLiquidacion(value);
//     };

//     const handleFechaPrimerVencimientoChange = (e) => {
//         const { value } = e.target;
//         setFechaPrimerVencimiento(value);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Realizar cálculos con los datos ingresados
//         // ...
//         // Guardar el resultado en el estado
//         setResultado('Aquí va el resultado de los cálculos');
//     };

//     return (
//         <div className="container">
//             <div className="row">
//                 <div className="col-lg-6">
//                     <form className="m-2 p-4 form-control" onSubmit={handleSubmit}>
//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="montoCredito" className="form-label">
//                                     Monto del crédito
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^[0-9]{1,10}$"
//                                     className="form-control"
//                                     id="montoCredito"
//                                     placeholder="Valor numérico"
//                                     value={montoCredito}
//                                     onChange={handleMontoCreditoChange}
//                                     title="Ingrese un valor numérico de hasta 10 dígitos"
//                                     required
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="tasa" className="form-label">
//                                     Ingrese la Tasa
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^\d{1,4}(\.\d{1,2})?$"
//                                     className="form-control"
//                                     id="tasa"
//                                     placeholder="TNA"
//                                     value={tasa}
//                                     onChange={handleTasaChange}
//                                     title="Ingrese un valor numérico del 0 al 1000 con hasta 2 decimales"
//                                     required
//                                 />
//                             </div>
//                         </fieldset>
//                         <div className="mb-3">
//                             <label htmlFor="customRange2" className="form-label">
//                                 Plazo en cantidad de meses: {plazo} meses (elige el plazo ideal usando la barra)
//                             </label>
//                             <input
//                                 type="range"
//                                 className="form-range col-sm-9"
//                                 min="0"
//                                 max="60"
//                                 id="customRange2"
//                                 value={plazo}
//                                 onChange={handlePlazoChange}
//                             />
//                         </div>

//                         <fieldset className="row mb-3">
//                             <legend className="col-form-label col-sm-2 pt-0">Vencimientos</legend>
//                             <div className="col-sm-9">
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios1"
//                                         value="mensuales"
//                                         checked={vencimientos === 'mensuales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios1">
//                                         Mensuales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios2"
//                                         value="trimestrales"
//                                         checked={vencimientos === 'trimestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios2">
//                                         Trimestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios3"
//                                         value="semestrales"
//                                         checked={vencimientos === 'semestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios3">
//                                         Semestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-switch form-check-inline form-check-reverse">
//                                     <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         id="flexSwitchCheckReverse"
//                                         disabled={vencimientos !== 'semestrales'}
//                                         checked={diferirVencimiento}
//                                         onChange={handleDiferirVencimientoChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="flexSwitchCheckReverse">
//                                         ¿Diferir primer vencimiento a un año?
//                                     </label>
//                                 </div>
//                             </div>
//                         </fieldset>

//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaLiquidacion" className="form-label">
//                                     Fecha de liquidación
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/([0-9]{2})$"
//                                     className="form-control"
//                                     id="fechaLiquidacion"
//                                     placeholder="Opcional"
//                                     value={fechaLiquidacion}
//                                     onChange={handleFechaLiquidacionChange}
//                                     title="Ingrese una fecha válida en formato dd/mm/aa"
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaPrimerVencimiento" className="form-label">
//                                     Fecha primer vencimiento
//                                 </label>
//                                 <input
//                                     type="text"
//                                     pattern="^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/([0-9]{2})$"
//                                     className="form-control"
//                                     id="fechaPrimerVencimiento"
//                                     placeholder="Opcional"
//                                     value={fechaPrimerVencimiento}
//                                     onChange={handleFechaPrimerVencimientoChange}
//                                     title="Ingrese una fecha válida en formato dd/mm/aa"
//                                 />
//                             </div>
//                         </fieldset>

//                         <div className="col-12">
//                             <button type="submit" className="btn btn-primary">
//                                 Calcular
//                             </button>
//                         </div>
//                     </form>
//                 </div>

//                 <div className="col-lg-6">
//                     <div className="m-2 p-4 custom-box1">
//                         <h5>Desarrollo del crédito:</h5>
//                         {resultado ? <div>{resultado}</div> : <div>Nada que Mostrar todavía</div>}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }





// import React, { useState } from 'react';
// import './FinancialForm.css';

// export default function FinancialForm() {
//     const [plazo, setPlazo] = useState(36);
//     const [vencimientos, setVencimientos] = useState('mensuales');
//     const [diferirVencimiento, setDiferirVencimiento] = useState(false);
//     const [montoCredito, setMontoCredito] = useState('');
//     const [tasa, setTasa] = useState('');
//     const [fechaLiquidacion, setFechaLiquidacion] = useState('');
//     const [fechaPrimerVencimiento, setFechaPrimerVencimiento] = useState('');
//     const [resultado, setResultado] = useState(null);

//     const handlePlazoChange = (e) => {
//         setPlazo(e.target.value);
//     };

//     const handleVencimientosChange = (e) => {
//         const selectedVencimientos = e.target.value;

//         // Desactivar el switch si los vencimientos cambian a mensuales o trimestrales
//         if (selectedVencimientos !== 'semestrales') {
//             setDiferirVencimiento(false);
//         }

//         setVencimientos(selectedVencimientos);
//     };

//     const handleDiferirVencimientoChange = () => {
//         if (vencimientos === 'semestrales') {
//             setDiferirVencimiento(!diferirVencimiento);
//         }
//     };

//     const handleMontoCreditoChange = (e) => {
//         setMontoCredito(e.target.value);
//     };

//     const handleTasaChange = (e) => {
//         setTasa(e.target.value);
//     };

//     const handleFechaLiquidacionChange = (e) => {
//         setFechaLiquidacion(e.target.value);
//     };

//     const handleFechaPrimerVencimientoChange = (e) => {
//         setFechaPrimerVencimiento(e.target.value);
//     };

//     const handleFormSubmit = (e) => {
//         e.preventDefault();

//         // Realizar los cálculos necesarios aquí
//         const resultadoCalculado = `Monto del crédito: ${montoCredito}, TNA: ${tasa}, Plazo: ${plazo} meses, Vencimientos: ${vencimientos}, Fecha de liquidación: ${fechaLiquidacion}, Fecha primer vencimiento: ${fechaPrimerVencimiento}`;

//         setResultado(resultadoCalculado);
//     };

//     return (
//         <div className="container">
//             <div className="row">
//                 <div className="col-lg-6">
//                     <form className="m-2 p-4 form-control" onSubmit={handleFormSubmit}>
//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="montoCredito" className="form-label">
//                                     Monto del crédito
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="montoCredito"
//                                     placeholder="Valor numérico"
//                                     value={montoCredito}
//                                     onChange={handleMontoCreditoChange}
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="tasa" className="form-label">
//                                     Ingrese la Tasa
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="tasa"
//                                     placeholder="TNA"
//                                     value={tasa}
//                                     onChange={handleTasaChange}
//                                 />
//                             </div>
//                         </fieldset>
//                         <div className="mb-3">
//                             <label htmlFor="customRange2" className="form-label">
//                                 Plazo en cantidad de meses: {plazo} meses (elige el plazo ideal usando la barra)
//                             </label>
//                             <input
//                                 type="range"
//                                 className="form-range col-sm-9"
//                                 min="0"
//                                 max="60"
//                                 id="customRange2"
//                                 value={plazo}
//                                 onChange={handlePlazoChange}
//                             />
//                         </div>

//                         <fieldset className="row mb-3">
//                             <legend className="col-form-label col-sm-2 pt-0">Vencimientos</legend>
//                             <div className="col-sm-9">
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios1"
//                                         value="mensuales"
//                                         checked={vencimientos === 'mensuales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios1">
//                                         Mensuales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios2"
//                                         value="trimestrales"
//                                         checked={vencimientos === 'trimestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios2">
//                                         Trimestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios3"
//                                         value="semestrales"
//                                         checked={vencimientos === 'semestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios3">
//                                         Semestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-switch form-check-inline form-check-reverse">
//                                     <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         id="flexSwitchCheckReverse"
//                                         disabled={vencimientos !== 'semestrales'}
//                                         checked={vencimientos === 'semestrales' && diferirVencimiento}
//                                         onChange={handleDiferirVencimientoChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="flexSwitchCheckReverse">
//                                         ¿Diferir primer vencimiento a un año?
//                                     </label>
//                                 </div>
//                             </div>
//                         </fieldset>

//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaLiquidacion" className="form-label">
//                                     Fecha de liquidación
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="fechaLiquidacion"
//                                     placeholder="Opcional"
//                                     value={fechaLiquidacion}
//                                     onChange={handleFechaLiquidacionChange}
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaPrimerVencimiento" className="form-label">
//                                     Fecha primer vencimiento
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="fechaPrimerVencimiento"
//                                     placeholder="Opcional"
//                                     value={fechaPrimerVencimiento}
//                                     onChange={handleFechaPrimerVencimientoChange}
//                                 />
//                             </div>
//                         </fieldset>

//                         <div className="col-12">
//                             <button type="submit" className="btn btn-primary">
//                                 Calcular
//                             </button>
//                         </div>
//                     </form>
//                 </div>

//                 <div className="col-lg-6">
//                     <div className="m-2 p-4 custom-box1">
//                         <h5>Desarrollo del crédito:</h5>
//                         {resultado ? <div>{resultado}</div> : <div>Nada que Mostrar todavía</div>}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }




// import React, { useState } from 'react';
// import './FinancialForm.css';

// export default function FinancialForm() {
//     const [plazo, setPlazo] = useState(36);
//     const [vencimientos, setVencimientos] = useState('mensuales');
//     const [diferirVencimiento, setDiferirVencimiento] = useState(false);

//     const handlePlazoChange = (e) => {
//         setPlazo(e.target.value);
//     };

//     const handleVencimientosChange = (e) => {
//         const selectedVencimientos = e.target.value;

//         // Desactivar el switch si los vencimientos cambian a mensuales o trimestrales
//         if (selectedVencimientos !== 'semestrales') {
//             setDiferirVencimiento(false);
//         }

//         setVencimientos(selectedVencimientos);
//     };

//     const handleDiferirVencimientoChange = () => {
//         if (vencimientos === 'semestrales') {
//             setDiferirVencimiento(!diferirVencimiento);
//         }
//     };

//     return (
//         <div className="container">
//             <div className="row">
//                 <div className="col-lg-6">
//                     <form className="m-2 p-4 form-control">
//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="montoCredito" className="form-label">
//                                     Monto del crédito
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="montoCredito"
//                                     placeholder="Valor numérico"
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="tasa" className="form-label">
//                                     Ingrese la Tasa
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="tasa"
//                                     placeholder="TNA"
//                                 />
//                             </div>
//                         </fieldset>
//                         <div className="mb-3">
//                             <label htmlFor="customRange2" className="form-label">
//                                 Plazo en cantidad de meses: {plazo} meses (elige el plazo ideal usando la barra)
//                             </label>
//                             <input
//                                 type="range"
//                                 className="form-range col-sm-9"
//                                 min="0"
//                                 max="60"
//                                 id="customRange2"
//                                 value={plazo}
//                                 onChange={handlePlazoChange}
//                             />
//                         </div>

//                         <fieldset className="row mb-3">
//                             <legend className="col-form-label col-sm-2 pt-0">Vencimientos</legend>
//                             <div className="col-sm-9">
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios1"
//                                         value="mensuales"
//                                         checked={vencimientos === 'mensuales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios1">
//                                         Mensuales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios2"
//                                         value="trimestrales"
//                                         checked={vencimientos === 'trimestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios2">
//                                         Trimestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-check-inline">
//                                     <input
//                                         className="form-check-input"
//                                         type="radio"
//                                         name="gridRadios"
//                                         id="gridRadios3"
//                                         value="semestrales"
//                                         checked={vencimientos === 'semestrales'}
//                                         onChange={handleVencimientosChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="gridRadios3">
//                                         Semestrales
//                                     </label>
//                                 </div>
//                                 <div className="form-check form-switch form-check-inline form-check-reverse">
//                                     <input
//                                         className="form-check-input"
//                                         type="checkbox"
//                                         id="flexSwitchCheckReverse"
//                                         disabled={vencimientos !== 'semestrales'}
//                                         checked={vencimientos === 'semestrales' && diferirVencimiento}
//                                         onChange={handleDiferirVencimientoChange}
//                                     />
//                                     <label className="form-check-label" htmlFor="flexSwitchCheckReverse">
//                                         ¿Diferir primer vencimiento a un año?
//                                     </label>
//                                 </div>
//                             </div>
//                         </fieldset>

//                         <fieldset className="row mb-3">
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaLiquidacion" className="form-label">
//                                     Fecha de liquidación
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="fechaLiquidacion"
//                                     placeholder="Opcional"
//                                 />
//                             </div>
//                             <div className="col-md-3">
//                                 <label htmlFor="fechaPrimerVencimiento" className="form-label">
//                                     Fecha primer vencimiento
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="fechaPrimerVencimiento"
//                                     placeholder="Opcional"
//                                 />
//                             </div>
//                         </fieldset>

//                         <div className="col-12">
//                             <button type="submit" className="btn btn-primary">
//                                 Calcular
//                             </button>
//                         </div>
//                     </form>
//                 </div>

//                 <div className="col-lg-6">
//                     <div className="m-2 p-4 custom-box1">
//                         <h5>Desarrollo del crédito:</h5>
//                         <div>Nada que Mostrar todavía</div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }


// style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '1rem' }}
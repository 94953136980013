import React from 'react'
import { ReactComponent as LogoFierros } from './logoFierros.svg';

export const Header=()=> {
    return (
        <div>
            <header className="p-3 mb-3 border-bottom">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none">
                            <LogoFierros width={35} height={35} />
                        </a>


                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            {/* <li><a href="#" className="nav-link px-2 link-secondary">Prendarios</a></li> */}
                            <li><a href="#" className="nav-link px-2 link-body-emphasis">Financiación</a></li>
                            {/* <li><a href="#" className="nav-link px-2 link-body-emphasis">Fierros</a></li>
                            <li><a href="#" className="nav-link px-2 link-body-emphasis">Concesionarias</a></li> */}

                            {/* <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
                                <input type="search" className="form-control" placeholder="Search..." aria-label="Search" />
                            </form>

                            <div className="dropdown text-end">
                                <a href="#" className="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle" />
                                </a>
                                <ul className="dropdown-menu text-small">
                                    <li><a className="dropdown-item" href="#">Reportes</a></li>
                                    <li><a className="dropdown-item" href="#">Perfil</a></li>
                                    <li><a className="dropdown-item" href="#">Cambiar Rol</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="#">Salir</a></li>
                                </ul>
                            </div> */}

{/* ESTO ES PARA AGREGAR BOTÓN QUE PERMITE PASAR DE TEMA CLARO A TEMA OSCURO
                            <li className="nav-item dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                                    <symbol id="circle-half" viewBox="0 0 64 64">
                                        <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
                                    </symbol>
                                    <symbol id="moon-stars-fill" viewBox="0 0 64 64">
                                        <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"></path>
                                        <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"></path>
                                    </symbol>
                                    <symbol id="sun-fill" viewBox="0 0 64 64">
                                        <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path>
                                    </symbol>
                                </svg>

                                <button className="btn btn-link nav-link py-2 px-0 px-lg-2 dropdown-toggle d-flex align-items-center" id="bd-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown" data-bs-display="static" aria-label="Toggle theme (light)">
                                    <svg className="bi my-1 theme-icon-active"><use href="#sun-fill"></use></svg>
                                    <span className="d-lg-none ms-2" id="bd-theme-text">Toggle theme</span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="bd-theme-text">
                                    <li>
                                        <button type="button" className="dropdown-item d-flex align-items-center active" data-bs-theme-value="light" aria-pressed="true">
                                            <svg className="bi me-2 opacity-50 theme-icon"><use href="#sun-fill"></use></svg>
                                            Light
                                            <svg className="bi ms-auto d-none"><use href="#check2"></use></svg>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="dark" aria-pressed="false">
                                            <svg className="bi me-2 opacity-50 theme-icon"><use href="#moon-stars-fill"></use></svg>
                                            Dark
                                            <svg className="bi ms-auto d-none"><use href="#check2"></use></svg>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="auto" aria-pressed="false">
                                            <svg className="bi me-2 opacity-50 theme-icon"><use href="#circle-half"></use></svg>
                                            Auto
                                            <svg className="bi ms-auto d-none"><use href="#check2"></use></svg>
                                        </button>
                                    </li>
                                </ul>
                            </li> */}


                        </ul>

                    </div>
                </div>
            </header>

        </div>
    )
}


